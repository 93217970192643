/*================================================================================
	Item Name: IVR - Market Place
	Version: 2.0
	Author: Abacies
	Author URL: #
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
[dir=ltr] .vertical-layout .main-menu .navigation a.active { background: linear-gradient(118deg,#314ec4,#c22939); box-shadow: 0 0 10px 1px rgba(#314ec4, 0.7); }

.main-menu .navbar-header .navbar-brand .brand-logo img { max-width: 100px; }
.menu-collapsed .main-menu .navbar-header .navbar-brand .brand-logo img { max-width: 45px; }
.menu-collapsed .main-menu .navbar-header .navbar-brand .brand-logo img + small { font-size: 7px; }


//nash

.cart-shopping, .pagination{
	margin: auto;
}
li.page-item.page-link{
	padding: 0 !important;
}
.apply-btn{
	color: none;
	background-color: none;
}
.shopping-cart, .label-cvv{
	margin-right: 10px;
}
.btn-cvv, .horizonatal_icon{
	margin-left: 10px;
}
.input-cvv{
	width: 50%;
}
input.form-check-input, .vertical_drop, .check_address_btn ,.address_vertical_edit{
	margin-right: 5px;
}
.required_color{
	color: #FB1011;
}
.shadows_card{
	box-shadow : 0 4px 24px 0 rgb(34 41 47 / 20%);
	border-radius: 6px;
}
// dhanya
// profile page 
.profile-header{
	
	img.rounded.img-fluid {
		width: 108px;
		height: 108px;
		border-radius: 6px;
		border: 4px solid rgb(255, 255, 255);
	}
	.profile-card-image {
		background-color: #f0808087; /* For browsers that do not support gradients */
  		background-image: linear-gradient(93.97deg, #141452 0.26%, rgba(218, 59, 59, 0) 98.44%);
	}
	img.card-img-top {
		opacity: 0;
	}
	.profile-details{
    padding: 0rem 1.5rem 0.1rem;
    margin-top: -6.5rem;
	display: flex;
    justify-content: space-between;
    align-items: flex-start;
	}
	.profile-content-section{
		display: flex;
		flex-direction: column;
		width: auto;
	}
	.profile-header-nav {
		padding: 0 2.5rem 0 12rem;
	}
	.content-pf{
		padding-left: 1rem;
	}


}

.edit-profile{
	.edit-profile-img{
		border-color: rgb(255, 255, 255) !important;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 24px rgba(34, 41, 47, 0.1);
	}

}

.remove-btn{
	font-size: 13px;
	color: #5f5974 !important;
	padding: 0.5rem;
	cursor: pointer;
}
.remove-btn:hover{
	opacity: 0.7 !important;
	
}
.address-edit{
	cursor: pointer;
	margin-left: 0.4rem;
}
.address-edit:hover{
	opacity: 0.7 !important;
	
}
.address_accordian_name{
	display: contents;
	text-transform: capitalize;
}
button.swal2-confirm.btn.btn-primary {
    margin-right: 1rem;
}
.cart-button {
	border-radius: 0.375rem !important;
}
// .setStatus {
// 	width: 175px;
// 	z-index: 99;
// }
// .action-select {
// 	z-index:100 !important;
// }

.outofstocktext:before {
	content: "";
	background: rgb(176 176 197 / 45%);
    border: 1px solid #ff0000ba;
    border-radius: 5px;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .outofstocktext:after {
	content: "OUT OF STOCK";
	position: absolute;
	background: rgb(253 100 100 / 94%)	;
	border-radius: 50%;
	width: 70px;
	height: 70px;
	top: calc(50% - 35px);
	left: calc(50% - 35px);
	font-size: 0.8em;
	color: #ffff;
	padding: 0.8rem;
	cursor: pointer;
	font-weight: bold;
	// display: flex;
	// align-items: center;
  }
  .outofstocktext:after:focus{
	opacity: 0.6;
  }
  .dataTable-filter-select {z-index: 10;}
  .delete-card{
	cursor: pointer !important;
  }
  .select-status{
	z-index: 9;
	width: 13rem !important;
  }
  .react-select.coupon-list{
    flex: 0 0 60%
}
.notification-dropdown{
	transform: translateY(-50px) !important;
}

//.................................................checkout Modal...............................
.terms-condition-modal {
	height: 350px;
	overflow-y: auto;
}
//.................................................FAQ............................................
.faq-header {
	font-size: 1.07rem;
	font-weight: 500;
  }
  
  .category-body {
	max-height:170px;
	overflow-y: auto;
  }
  
  .faq-nav .nav-pills .nav-link {
	border-radius: 1rem !important;
  }
  
  .nav-faq {
	flex-wrap: nowrap;
	overflow-x: auto;
  }
  //....................................Nav Btn Dropdown....................................//
  .nav-btn-dropdown .dropdown-menu[data-popper-placement]:not([data-popper-placement^=top-]) {
    top: 2.95rem !important;
    /* left: 3rem !important; */
}
.agent-sort .dataTable-select {
	width:5rem !important;
}
//..login new
.login-form-container {
	width:50%;
}
.auth-login-form {
	.input-group {
	  .form-control {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 10rem;
		border-bottom-left-radius: 10rem;
	  }
	}
	.input-group-text {
	  border-top-right-radius: 10rem;
	  border-bottom-right-radius: 10rem;
	}
	.custom-button-login {
	  background-image: linear-gradient(90deg, #11bde1, #808cff)
	}
  }
  .custom-button{
	background-color: #725dd4;
  border-radius: 0.358rem;
  padding: 5px;
  a {
    color:#fff !important;
  }
  }
  .ai-button {
	border: 1px solid #e93d3d;
	padding: 7px;
	border-radius: 0.358rem;
  }
  .auth-wrap {
	height:100vh;
	background: linear-gradient(118deg, rgba(#9488bc, 1), rgba(#9488bc, 0.7));
    background-repeat: no-repeat;
}
  .card-success {
	background: linear-gradient(118deg, rgba(#141452, 1), rgba(#141452, 0.7));
    color: #fff;
	.avatar svg {
		height: 28px !important;
		width: 28px !important;
	  }
	  a {
		color: orange
	  }
}
.terms-link:hover {
	border-bottom: 2.5px solid #141452;
}
.terms-button {
	transition: transform 0.3s ease-in;
}
.terms-button:hover {
	transform: scale(1.05);
}
@media (max-width:1024px) {
	.login-form-container {
		width:100%;
	}
}